

//HEADER ANIMATION
var scrollUp = document.querySelector('.header');

ScrollTrigger.create({
  start: 'top -250',
  end: 99999,
  
  toggleClass: {className: 'header--scrolled', targets: '.header'}
});

document.querySelector('.nav-mobile').onclick = function(){
    document.querySelector('.menu-mobile').classList.add('active');
   
};
document.querySelector('.menu-mobile_close').onclick = function(){
    document.querySelector('.menu-mobile').classList.remove('active');
   
};
document.querySelector('.menu-mobile nav').onclick = function(){
    document.querySelector('.menu-mobile').classList.remove('active');
   
};


function setRRSSLinks() {
    let urlPage = window.location.href;

    //Twitter
    let shareTW = document.getElementById("shareTW")
    let twText = shareTW.getAttribute("data-text")
    let twHref = "https://twitter.com/intent/tweet?url="+urlPage+"&text="+twText+"&original_referer="+urlPage
    shareTW.setAttribute("href",twHref)
    
     //Facebook
    let shareFB = document.getElementById("shareFB")
    let fbHref = "https://www.facebook.com/sharer/sharer.php?u="+urlPage
    shareFB.setAttribute("href",fbHref)

    //WhatsApp
    /*let shareWA = document.getElementById("shareWA")
    let waText = shareWA.getAttribute("data-text")
    let waHref = "https://api.whatsapp.com/send?text="+waText+" "+urlPage
    shareWA.setAttribute("href",waHref)*/

	 //Linkedin
	let shareLK = document.getElementById("shareLK")
	let lkText = shareLK.getAttribute("data-text")
	let lkHref = "https://www.linkedin.com/shareArticle?mini=true&url="+urlPage+"&title="+lkText+"&summary=&source="
	shareLK.setAttribute("href",lkHref)
}

 




// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);



/// ANCHOR 


/// FIN ANCHOR 

/*const section1 = document.body.querySelector('.work')
const box = document.body.querySelector('.work-content')

const tl = gsap.timeline({ paused: true })

tl.fromTo(box, { y: 0 }, { y: '40vh', duration: 1, ease: 'none' }, 0)

const st = ScrollTrigger.create({
	animation: tl,
	trigger: section1,
	start: 'top top',
	end: 'bottom top',
	scrub: true
})*/

